import store from '@/store'
store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'SET_USER':
            if (mutation.payload) {
                if (localStorage.getItem('userData')) {
                    return;
                }
                localStorage.setItem('userData', JSON.stringify(mutation.payload))
            }
            else {
                console.log("mutation.payload")
                localStorage.removeItem('userData')
            }
            break;
    }
})