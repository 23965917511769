import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["authenticated"]) {
        return next({
          name: "CustomersUsers",
        });
      }
      next();
    },
  },
  {
    path: "/product_category",
    name: "product_category",
    component: () => import(/* webpackChunkName: "product_category" */ "../views/ProductsCategory.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/filters",
    name: "filters",
    component: () => import(/* webpackChunkName: "filters" */ "../views/Filters.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/customers_users",
    name: "CustomersUsers",
    component: () => import(/* webpackChunkName: "customers_users" */ "../views/Users/Customers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/store_users",
    name: "StoreUsers",
    component: () => import(/* webpackChunkName: "store_users" */ "../views/Users/SellerUsers.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/store_users_temp",
    name: "StoresTemp",
    component: () => import(/* webpackChunkName: "store_users_temp" */ "../views/Users/SellerUsersTemp.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/store_users_deleted",
    name: "StoresDeleted",
    component: () => import(/* webpackChunkName: "store_users_temp" */ "../views/Users/SellerUsersDeleted.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/admins_users",
    name: "AdminsUsers",
    component: () => import(/* webpackChunkName: "admins_users" */ "../views/Users/Admins.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/mandoob_users",
    name: "MandoobUsers",
    component: () => import(/* webpackChunkName: "mandoob_users" */ "../views/Users/Mandoob.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/singleMandoob/:id",
    name: "SingleMandoob",
    props: true,
    component: () => import(/* webpackChunkName: "single_mandoob" */ "../views/Users/SingleMandoob.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/stores",
    name: "Store",
    component: () => import(/* webpackChunkName: "stores" */ "../views/Stores.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import(/* webpackChunkName: "orders" */ "../views/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/products",
    name: "Products",
    component: () => import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/brands",
    name: "Brand",
    component: () => import(/* webpackChunkName: "brands" */ "../views/Brands.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/governorates",
    name: "Govs",
    component: () => import(/* webpackChunkName: "governorates" */ "../views/Govs.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/posts",
    name: "Posts",
    component: () => import(/* webpackChunkName: "posts" */ "../views/Posts.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/ads",
    name: "Ads",
    component: () => import(/* webpackChunkName: "ads" */ "../views/Ads.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
  {
    path: "/icons",
    name: "Icons",
    component: () => import(/* webpackChunkName: "icons" */ "../views/Icons.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next({
          name: "auth",
        });
      }
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
