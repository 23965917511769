<template>
  <div>
    <v-app-bar app outlined color="secondary" dark>
      <p class="ma-0">Mamnoon Admin Panel</p>
      <v-spacer></v-spacer>
      <v-btn icon @click="Logout"><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>

    <v-navigation-drawer right width="280" app permanent dark class="secondary">
      <v-sheet
        class="d-flex justify-center align-center"
        color="secondary"
        height="250"
      >
        <v-img width="150" height="150" contain src="@/assets/logo.png"></v-img>
      </v-sheet>
      <v-list class="pa-0">
        <v-list-item
          link
          v-for="item in navItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-content>
            <v-list-item-title
              ><p class="ma-0 text-end">{{ item.title }}</p></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

import { mapActions } from "vuex";
export default {
  data: () => ({
    navItems: [
      { title: "الزبائن", path: "/customers_users" },
      { title: "أصحاب المتاجر", path: "/store_users" },
      { title: "اصحاب المتاجر غير المسجلين", path: "/store_users_temp" },
      { title: "اصحاب المتاجر المحذوفين", path: "/store_users_deleted" },
      { title: "المسؤولين", path: "/admins_users" },
      { title: "المندوبين", path: "/mandoob_users" },
      { title: "الطلبات", path: "/orders" },
      { title: "المتاجر", path: "/stores" },
      { title: "المنتجات", path: "/products" },
      { title: "أقسام المنتجات", path: "/product_category" },
      { title: "الماركات", path: "/brands" },
      { title: "الفلاتر", path: "/filters" },
      { title: "المنشورات", path: "/posts" },
      { title: "الاعلانات", path: "/ads" },
      { title: "المناطق", path: "/governorates" },
      { title: "تقارير", path: "/actions" },
      { title: "الايكونات", path: "/icons" },
    ],
  }),
  methods: {
    ...mapActions({
      fetchUser: "fetchUser",
    }),
    Logout() {
      const auth = getAuth();

      signOut(auth).then(() => {
        this.fetchUser(null);
        this.$router.replace({
          name: "auth",
        });
      });
    },
  },
};
</script>
