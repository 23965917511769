export default {
  methods: {
    getDate(item) {
      var dateParts = item.split(/\D+/);
      var returnDate = new Date();
      returnDate.setUTCFullYear(parseInt(dateParts[0]));
      returnDate.setUTCMonth(parseInt(dateParts[1] - 1));
      returnDate.setUTCDate(parseInt(dateParts[2]));
      returnDate.setUTCHours(parseInt(dateParts[3]));
      returnDate.setUTCMinutes(parseInt(dateParts[4]));
      returnDate.setUTCSeconds(parseInt(dateParts[5]));
      returnDate.setUTCMilliseconds(parseInt(dateParts[6]));
      var timezoneOffsetHours = 0;
      if (dateParts[7] || dateParts[8]) {
        var timezoneOffsetMinutes = 0;
        if (dateParts[8]) {
          timezoneOffsetMinutes = parseInt(dateParts[8]) / 60;
        }
        timezoneOffsetHours = parseInt(dateParts[7]) + timezoneOffsetMinutes;
        if (item.created_at.substr(-6, 1) == "+") {
          timezoneOffsetHours *= -1;
        }
      }
      returnDate.setHours(returnDate.getHours() + timezoneOffsetHours);
      var d = new Date(returnDate),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("/");
    },
  },
};
