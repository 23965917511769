import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { firestorePlugin } from 'vuefire'
import dateConverter from "./dateConverter";
import JsonExcel from "vue-json-excel";

Vue.use(firestorePlugin)
require('@/store/subscriber')
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.mixin(dateConverter);

store.dispatch('fetchUser', JSON.parse(localStorage.getItem('userData'))).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
Vue.component("downloadExcel", JsonExcel);
